import React from 'react';

import styles from './rsvp-page.module.scss';


export default ({ attending }) => {
  return attending ?
  <div className={styles.confirmation}>
    <h1 className={styles.header}>GREAT !</h1>
    <p>We're so excited you can make it!</p>
    <p>If you have any questions, please feel free to reach out.</p>
    <p>See you there!</p>
  </div> :
  <div className={styles.confirmation}>
    <h1 className={styles.header}>:(</h1>
    <p>Thanks for taking the time to RSVP.</p>
    <p>We're so sorry you can't make it, but understand!</p>
    <p>Can't wait to see you some other time!</p>
  </div>
};